import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Typography from '@material-ui/core/Typography';
import styles from './IoTDevelopment.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import SuccessStoriesBanner from '../SuccessStoriesBanner';
import UseCases from '../UseCases';
import {
  IOT_ADVANTAGES_CONTENT, IOT_HEADING, IOT_INSIGHTS_HEADING, IOT_SERVICES_CARDS, IOT_SERVICES_HEADING,
  IOT_SERVICES_TEXT, IOT_SOLUTIONS_CARDS, IOT_SOLUTIONS_HEADING, IOT_SOLUTIONS_TEXT, IOT_SUBHEADING,
  IOT_SUCCESS_STORIES_SUBHEADING, IOT_USE_CASES_CONTENT, IOT_USE_CASES_HEADING
} from '../../constants/iot';

const IoTDevelopment = () => {
  const {bg, icons, iotPosts, minus, plus, successStoriesBackground, useCasesImages} = useStaticQuery(graphql`
      query {
          bg: allFile(
              filter: {
                  sourceInstanceName: { eq: "IOT" }
                  name: { eq: "bg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          icons: allFile(
              filter: {
                  sourceInstanceName: { eq: "IOT" }
                  extension: { eq: "svg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          useCasesImages: allFile(
              filter: {
                  sourceInstanceName: { eq: "IOT" }
                  relativeDirectory: { eq: "use-cases" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          plus: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "plus" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          minus: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "minus" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          successStoriesBackground: allFile(
              filter: {
                  sourceInstanceName: { eq: "otherImages" }
                  name: { eq: "success-stories-bg" }
              }
          ) {
              nodes {
                  name
                  publicURL
              }
          }
          iotPosts: allMarkdownRemark(
              filter: {
                  fields: {
                      slug: {
                          in: ["/posts/10-best-iot-platforms-2021", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies"]
                      }
                  }
                  frontmatter: {
                      template: {
                          eq: "post"
                      }
                      draft: {
                          ne: true
                      }
                  }
              }
          ) {
              nodes {
                  fields {
                      slug
                      categorySlug
                      tagSlugs
                  }
                  frontmatter {
                      title
                      date
                      category
                      description
                      socialImage
                      tags
                  }
              }
          }
      }
  `);

  const iconsMap = icons.nodes.reduce((acc, icon) => ({...acc, [icon.name]: icon.publicURL}), {});
  const useCasesImagesMap = useCasesImages.nodes.reduce((acc, img) => ({...acc, [img.name]: img.publicURL}), {});
  const postsEdges = iotPosts.nodes.map(node => ({node}));

  return (
    <div className={styles['iot-page']}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        className={styles['iot-banner']}
        description={IOT_SUBHEADING}
        descriptionClassName={styles['iot-banner-description']}
        text={IOT_HEADING}
        textClassName={styles['iot-banner-text']}
      />
      <div className={styles['section-container']}>
        <div className={styles['container']}>
          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {IOT_SERVICES_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{IOT_SERVICES_TEXT}</Typography>
          <div className={styles['card-list']}>
            {IOT_SERVICES_CARDS.map(({icon, text, title}) => {
              return <div className={styles['expanding-card']} key={icon}>
                <ExpandingCard
                  collapser={minus.nodes[0].publicURL}
                  expander={plus.nodes[0].publicURL}
                  img={iconsMap[icon]}
                  smallCard
                >
                  {{
                    collapsedView: <Typography
                      className={styles['expanding-card-heading']}
                      color={'secondary'}
                      variant={'h4'}
                    >
                      {title}
                    </Typography>,
                    expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                      {text.map((paragraph, index) => <p
                        className={styles['expanding-card-paragraph']}
                        key={`${icon}-${index}`}
                      >
                        {paragraph}
                      </p>)}
                    </div>
                  }}
                </ExpandingCard>
              </div>;
            })}
          </div>
        </div>
      </div>
      <SuccessStoriesBanner
        backgroundImage={successStoriesBackground.nodes[0].publicURL}
        subHeading={IOT_SUCCESS_STORIES_SUBHEADING}
      />
      <div className={`${styles['section-container']}`}>
        <div className={`${styles['container']}`}>
          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {IOT_SOLUTIONS_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{IOT_SOLUTIONS_TEXT}</Typography>
          <div className={styles['card-list']}>
            {IOT_SOLUTIONS_CARDS.map(({icon, text, title}) => {
              return <div className={styles['expanding-card']} key={icon}>
                <ExpandingCard
                  collapser={minus.nodes[0].publicURL}
                  expander={plus.nodes[0].publicURL}
                  img={iconsMap[icon]}
                  smallCard
                >
                  {{
                    collapsedView: <Typography
                      className={styles['expanding-card-heading']}
                      color={'secondary'}
                      variant={'h4'}
                    >
                      {title}
                    </Typography>,
                    expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                      {text.map((paragraph, index) => <p
                        className={styles['expanding-card-paragraph']}
                        key={`${icon}-${index}`}
                      >
                        {paragraph}
                      </p>)}
                    </div>
                  }}
                </ExpandingCard>
              </div>;
            })}
          </div>
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles['non-white']}`}>
        <div className={styles['container']}>
          <Typography align="center" className={styles['heading']} color="secondary" variant="h2">
            {IOT_USE_CASES_HEADING}
          </Typography>
          <UseCases content={IOT_USE_CASES_CONTENT} images={useCasesImagesMap} />
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles.advantages}`}>
        <div className={styles['container']}>
          <Advantages content={IOT_ADVANTAGES_CONTENT} />
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles['non-white']}`}>
        <div className={styles['container']}>
          <Typography
            align="center"
            className={styles['heading']}
            color="secondary"
            key="insights-heading"
            variant="h2"
          >
            {IOT_INSIGHTS_HEADING}
          </Typography>
          <Feed edges={postsEdges} key="insights-feed" />
        </div>
      </div>
      <LeaveMessageBanner />
    </div>
  );
};

export default IoTDevelopment;
