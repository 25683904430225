export const IOT_HEADING = ['IoT Development'];
export const IOT_SUBHEADING = ['As the creators of the Kaa IoT Platform, CyberVision has been engaged in IoT projects since the earliest days of this technology. More than just building an IoT solution, we can implement end-to-end IoT technology to support your company’s entire digital transformation strategy.'];

export const IOT_SERVICES_HEADING = 'IoT Development Services';
export const IOT_SERVICES_TEXT = 'Here at CyberVision, the Internet of Things is one of our core competencies and a major area of our R&D. We’ve been engaged in the development of numerous IoT solutions and smart, connected products, such as: IoT embedded firmware and OS development, IoT application middleware, software development for smart products, smart products hardware design, Industrial IoT, digital signal processing, IoT data analytics and data analysis algorithms, machine learning for autonomous IoT solutions.';
export const IOT_SERVICES_CARDS = [
  {
    title: 'Smart product development',
    text: ['Streamline implementation of your smart product solution, eliminate IoT development risks, and ensure fast time-to-market for your product. Expand your product market reach and strengthen its competitiveness by implementing modern IoT features and services. Upgrade traditional products with IoT.'],
    icon: 'smart-product-development'
  },
  {
    title: 'IoT platform implementation',
    text: ['Make IoT your company’s core expertise by developing a branded IoT platform or leveraging existing platforms from IoT tech vendors. Select the best IoT technology stack for your business use cases and future roadmap. Improve business performance by integrating multiple IoT solutions on a single platform.'],
    icon: 'iot-platform-implementation'
  },
  {
    title: 'IoT dashboards',
    text: ['Create user-friendly, flexible IoT dashboards to visualize your device data in multiple formats, enable real-time device monitoring & analytics, track device health data, and set up rule-based smart alerts. Design IoT dashboards according to your business workflows and user needs to streamline operations.'],
    icon: 'iot-dashboards'
  },
  {
    title: 'IoT security',
    text: ['Enable end-to-end security for your IoT solution or entire IoT ecosystem. Protect your data stores, ensure encryption for device communications, implement the best practices for device authentication, device revocation, user management, and tenant administration.'],
    icon: 'iot-security'
  },
  {
    title: 'Identity and access management',
    text: ['Automate access management policies and set up a hierarchy of user access roles according to your organizational structure. Ensure that every user has access only to those assets, devices, applications, and data that is dictated by the security policies of your company; set up specific limitations for each user role.'],
    icon: 'identity-and-access-management'
  },
  {
    title: 'IoT technology migration',
    text: ['Transfer your IoT data and applications to a different cloud vendor’s infrastructure, a different IoT platform, or upgrade your technology stack. Move your outdated IoT solution to a modern microservice architecture that allows for more efficient cloud-native deployment and faster development of new features.'],
    icon: 'iot-technology-migration'
  },
  {
    title: 'IoT data analytics',
    text: ['Set up data analytics pipelines for your IoT solution and visualize insights gathered from your IoT data. Implement popular data analytics solutions and services for your IoT use case, tap into your customer’s behavior data, understand how to further improve your product, and monetize your IoT data analytics by providing it as a service.'],
    icon: 'iot-data-analytics'
  },
  {
    title: 'DevOps/CloudOps',
    text: ['Set up data analytics pipelines for your IoT solution and visualize insights gathered from your IoT data. Implement popular data analytics solutions and services for your IoT use case, tap into your customer’s behavior data, understand how to further improve your product, and monetize your IoT data analytics by providing it as a service.'],
    icon: 'devops-cloudops'
  }
];
export const IOT_SUCCESS_STORIES_SUBHEADING = [
  'We’ve implemented IoT solutions for companies from over 10 major industries and, for many of them, put their traditional business models on the modern digital rails. We can show you how.'
];

export const IOT_SOLUTIONS_HEADING = 'IoT Solutions';
export const IOT_SOLUTIONS_TEXT = 'We deliver production-ready IoT solutions that address each aspect of the modern IoT stack, from hardware and connectivity to IoT middleware and cloud services. We also combine IoT with other areas of our expertise, such as Big Data, Cloud, and Mobile, to deliver large-scale projects involving multiple cutting-edge technologies.';
export const IOT_SOLUTIONS_CARDS = [
  {
    title: 'Smart products and IoT applications',
    text: [
      'The Internet of Things is drastically changing the landscape of consumer and industrial markets: customers expect smarter products and more personalized experiences, manufacturers rearrange their production facilities to take advantage of the greater degree of flexibility and autonomy enabled by IoT, and service providers optimize their operations by closely monitoring and analyzing their performance data.',
      'This occurs as the market is gradually shifting from discrete, isolated products toward solutions that add an extra layer of intelligence into traditional things. We can now easily track vehicles and cargo in real time, remotely control household appliances from our smartphones, or prevent equipment failures by continuously collecting and analyzing their operational data. We can even connect multiple products together in a way that amplifies their total worth and usefulness. Software and connectivity components open a new range of capabilities for anything you may think of: car keys, Barbie dolls, heart monitors, or forklifts.',
      'CyberVision can build a smart product solution from scratch or assist your own product team with high-quality IoT development. Our deep understanding of the IoT solutions lifecycle, popular use cases for different industries, and the corresponding technology stacks, allows us to deliver projects of any complexity and scale.'
    ],
    icon: 'smart-products-iot-applications'
  },
  {
    title: 'IoT big data analytics',
    text: [
      'One of the best ways how IoT augments the value of modern devices and services is by collecting and analyzing their diverse data. This includes device operational data to help operators analyze performance metrics, as well as user data to help marketers create better experiences. Technical impediments are no longer the case when it comes to extracting the right information - with modern sensors and microprocessors combined with IoT applications, it is possible to track and analyze anything that can be connected to the Internet.',
      'That said, certain use cases are positioned exceptionally well for IoT data analytics. One of these is predictive maintenance, which helps detect early signs of device malfunctions or wear and tear. Smart meter data analytics is also a popular solution that helps companies optimize their electricity consumption profile and improve other efficiencies. Other popular IoT analytics examples, which CyberVision can build for your company, include sensor data monitoring and rule-based alerts, user behavior analytics and personalized experiences, edge analytics, etc.',
      'CyberVision takes IoT data analytics to an even greater level with our approach to building user-friendly, rich analytics dashboards. With our broad knowledge of different IoT use cases and industries, we deliver science-backed and industry-proven UI/UX design that matches your unique way of doing business.'
    ],
    icon: 'iot-data-analytics'
  },
  {
    title: 'Large-scale IoT solutions',
    text: [
      'IoT development is a key part of modern large-scale projects for smart city, industrial automation, or smart grid. By connecting hundreds of IoT devices into a single ecosystem, such projects achieve a new level of automation, efficiency, and quality of service. However, to ensure seamless integration between diverse devices coming from different vendors, it is necessary to overcome a number of technological challenges, such as setting up the cloud infrastructure, ensuring secure connectivity and device management, selecting an appropriate IoT platform or developing a custom solution, taking care of required scalability and performance, and many others.',
      'CyberVision has broad experience with building end-to-end IoT solutions for smart city, smart energy, industrial, telecom and other large-scale applications. We combine our knowledge of IoT with our vast expertise in cloud, Big Data, and embedded domains as well as our hands-on experience with popular IoT platforms. Whether it’s an IoT development project from scratch, legacy upgrade, or system integration, we can deliver large-scale IoT solutions that meet and exceed your expectations.'
    ],
    icon: 'large-scale-iot-solutions'
  }
];

export const IOT_USE_CASES_HEADING = 'Use cases & industries';
export const IOT_USE_CASES_CONTENT = [
  {
    title: 'Industrial IoT',
    text: 'Remote equipment monitoring & control, sensor-based predictive maintenance, end-to-end supply chain monitoring, asset & fleet management, and performance analytics.',
    image: 'industrial-iot'
  },
  {
    title: 'Consumer electronics',
    text: 'Embedded software for smart electronics, remote control & performance monitoring, device management, smart product mobile applications, and user behavior analytics.',
    image: 'consumer-electronics'
  },
  {
    title: 'Smart healthcare',
    text: 'HIPAA-compliant smart medical devices, firmware and algorithms for wearable devices, device monitoring, control, and analytics, integration with EMR systems, medical device data integration and analytics.',
    image: 'smart-healthcare'
  },
  {
    title: 'IoT for Telecom',
    text: 'IoT SaaS for Telecoms, cloud-based services for IoT devices such as smart home or fleet management, location-based Telecom services, IoT data analytics pipelines.',
    image: 'iot-for-telecom'
  },
  {
    title: 'Smart building',
    text: 'Indoor climate monitoring and control, smart doors, smart elevators, etc.',
    image: 'smart-building'
  },
  {
    title: 'Smart energy',
    text: 'Smart connected energy batteries, smart heaters, smart metering solutions for various use cases, IoT dashboards for energy profile management.',
    image: 'smart-energy'
  }
];

export const IOT_ADVANTAGES_CONTENT = [
  {
    number: '01',
    title: 'Expertise',
    text: 'Vast experience in IoT product development'
  },
  {
    number: '02',
    title: 'People',
    text: 'Practical knowledge of common IoT technology stacks in different industries'
  },
  {
    number: '03',
    title: 'Partnership',
    text: 'Creators of the Kaa IoT Platform, one of the first open-source IoT technologies'
  },
  {
    number: '04',
    title: 'Contributions',
    text: 'Over 5 years of constant involvement in cutting-edge IoT development, with dozens of completed projects'
  },
  {
    number: '05',
    title: 'Experience',
    text: 'Flexible service delivery model, from turnkey IoT solutions to a dedicated development team'
  }
];

export const IOT_INSIGHTS_HEADING = 'Insights';
